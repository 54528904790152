'use client';

import { UserIcon as UserOutline } from '@heroicons/react/24/outline';
import { useUserDrawerActions } from '@/store/useUserDrawer';

export default function UserButton() {
  const { openUserDrawer } = useUserDrawerActions();

  return (
    <button
      className="flex items-center justify-center px-3 transition md:hover:!opacity-100 group-hover:md:opacity-60"
      onClick={openUserDrawer}
    >
      <UserOutline className="h-6" />
    </button>
  );
}
