import Input from '@/components/Input';
import Button, { ButtonVariant } from '@/components/Button';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthStore } from '@/store/useAuthStore';
import { useTranslations } from 'next-intl';
export default function LoginHairdresserForm() {
  const [orderRef, setOrderRef] = useState(null);
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const [isPollingBankid, setIsPollingBankid] = useState(false);
  const setUser = useAuthStore((state) => state.setUser);
  const setAuthentication = useAuthStore((state) => state.setAuthentication);
  const translation = useTranslations('HairdresserLogin');
  const validateLoginSchema = Yup.object().shape({
    personal_number: Yup.string()
      .matches(
        /^(19|20)\d{10}$/,
        translation('Validation.personal_number_matches')
      )
      .required(translation('Validation.personal_number')),
  });
  useEffect(() => {
    if (orderRef) {
      setIsPollingBankid(true);
      const interval = setInterval(async () => {
        try {
          const response = await fetch(`/api/hairdresser/login/second-step`, {
            cache: 'no-cache',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orderRef: orderRef }),
          });
          const data = await response.json();
          if (data.redirect) {
            setUser(data.data);
            setAuthentication(true);
            router.replace('/hairdresser/dashboard');
          }
        } catch (error) {
          setErrorMessage(translation('error'));
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [orderRef, router, setAuthentication, setUser, translation]);
  return (
    <Formik
      validationSchema={validateLoginSchema}
      initialValues={{ personal_number: '' }}
      onSubmit={async (values, actions) => {
        setErrorMessage('');
        const orderRefResponse = await fetch(
          `/api/hairdresser/login/first-step`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ personal_number: values.personal_number }),
          }
        );
        const data = await orderRefResponse.json();
        if (data.message) {
          setErrorMessage(data.message);
        }
        if (data.orderRef) {
          setOrderRef(data.orderRef);
          actions.resetForm();
        }
      }}
      className="flex items-center justify-center"
    >
      {({ handleSubmit, errors, touched, isSubmitting }) => (
        <Form
          onChange={() => setErrorMessage('')}
          onSubmit={handleSubmit}
          className="mt-4 flex w-full flex-col gap-2"
        >
          <label>{translation('personalNumberLabel')}</label>
          <Field name="personal_number">
            {({ field }: FieldProps) => (
              <Input
                placeholder={translation('defaultPersonalNumber')}
                className={`${(errors.personal_number && touched.personal_number) || errorMessage ? 'border-red-500' : ''}`}
                {...field}
              />
            )}
          </Field>

          <p
            className={`${(errorMessage && 'pt-2') || (errors.personal_number && touched.personal_number && 'pt-2')}  text-sm text-red-500`}
          >
            {errorMessage && !errors.personal_number && (
              <span>{errorMessage}</span>
            )}
            <ErrorMessage name="personal_number" />
          </p>

          <Button
            disabled={isSubmitting}
            variant={ButtonVariant.Primary}
            className=""
            type="submit"
          >
            {isSubmitting || isPollingBankid
              ? translation('button-submitting')
              : translation('button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
