'use client';

// import Link from "next/link";
import Header from '../Header';

import {
  useUserDrawerIsOpen,
  useUserDrawerActions,
} from '@/store/useUserDrawer';

import useStore from '@/store/useStore';
import { useUserRole, useUserRoleActions, UserRole } from '@/store/useUserRole';

import LoginForm from '@/components/LoginCustomerForm';
import LoginHairdresserForm from '@/components/LoginHairdresserForm';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useAuthStore } from '@/store/useAuthStore';
import UserRoutesNav from '@/components/UserRoutesNav';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

export default function UserDrawer() {
  const router = useRouter();
  const userDrawerIsOpen = useUserDrawerIsOpen();
  const authenticated = useAuthStore((state) => state.authenticated);
  const loggedInUser = useAuthStore((state) => state.user);
  const { closeUserDrawer } = useUserDrawerActions();
  const userRole = useStore(useUserRole, (userRole) => userRole);
  const translation = useTranslations('UserDrawer');
  const { setUserRole } = useUserRoleActions();
  const pathName = usePathname();

  useEffect(() => {
    closeUserDrawer();
  }, [pathName, closeUserDrawer]);

  const protectedCustomerRoutes = [
    { name: translation('protectedRoutes.bookings'), link: '/customer' },
    {
      name: translation('protectedRoutes.subscription'),
      link: '/customer/subscription',
    },
    { name: translation('protectedRoutes.profile'), link: '/customer/profile' },
  ];

  const protectedHairdresserRoutes = [
    {
      name: translation('protectedRoutes.dashboard'),
      link: '/hairdresser/dashboard',
    },
    {
      name: translation('protectedRoutes.profile'),
      link: '/hairdresser/profile',
    },
    {
      name: translation('protectedRoutes.chairs'),
      link: '/hairdresser/chairs',
    },
    {
      name: translation('protectedRoutes.finance'),
      link: '/hairdresser/finance',
    },
    {
      name: translation('protectedRoutes.messages'),
      link: '/hairdresser/message',
    },
    {
      name: translation('protectedRoutes.gallery'),
      link: '/hairdresser/gallery',
    },
    {
      name: translation('protectedRoutes.inquiry'),
      link: '/hairdresser/inquiry',
    },
  ];

  return (
    <div
      className={`
        pointer-events-none fixed inset-0 z-[10000] transform overflow-hidden bg-darkblue-950 transition-all duration-300 ease-in-out 
        ${userDrawerIsOpen ? 'bg-opacity-60 backdrop-blur-sm' : 'bg-opacity-0 backdrop-blur-none'}
      `}
    >
      <div
        className={`
        pointer-events-auto absolute right-0 h-full w-screen max-w-sm transform bg-white text-black transition-all duration-300 ease-in-out 
          ${userDrawerIsOpen ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        <Header onClick={closeUserDrawer} />
        <div className="px-4">
          <div className="relative flex h-16 rounded-full bg-gray-50 p-2 font-semibold">
            <div className="absolute left-0 z-10 flex h-12 w-full px-2">
              <button
                className={`flex-1 transition-colors ${
                  userRole === UserRole.Customer ? 'text-white' : ''
                }`}
                onClick={() => setUserRole(UserRole.Customer)}
              >
                {translation('customerTitle')}
              </button>
              <Link
                className={`flex flex-1 items-center justify-center transition-colors ${
                  userRole === UserRole.Hairdresser ? 'text-white' : ''
                }`}
                href="https://member.gentsgo.com/hd"
              >
                {translation('hairdresserTitle')}
              </Link>
            </div>
            <div
              className={`h-12 w-1/2 rounded-full bg-blue-600 transition ${
                userRole === UserRole.Customer
                  ? 'translate-x-0'
                  : 'translate-x-full'
              }`}
            />
          </div>
        </div>
        <div className="mx-auto mt-4 w-full  px-4">
          {userRole === UserRole.Customer ? (
            <>
              {!authenticated ? (
                <div className="p-2">
                  <h2 className=" text-lg font-semibold">
                    {translation('customerLoginTitle')}
                  </h2>
                  <LoginForm />
                </div>
              ) : (
                loggedInUser?.role === 'customer' && (
                  <UserRoutesNav routes={protectedCustomerRoutes} />
                )
              )}
            </>
          ) : (
            userRole === UserRole.Hairdresser && (
              <>
                {!authenticated ? (
                  <div className="p-2">
                    <h2 className=" text-lg font-semibold">
                      {translation('hairdresserLoginTitle')}
                    </h2>
                    <LoginHairdresserForm />
                  </div>
                ) : (
                  loggedInUser?.role === 'hairdresser' && (
                    <UserRoutesNav routes={protectedHairdresserRoutes} />
                  )
                )}
              </>
            )
          )}
        </div>
      </div>
      {userDrawerIsOpen && (
        <div
          className="pointer-events-auto h-full w-screen cursor-pointer"
          onClick={closeUserDrawer}
        ></div>
      )}
    </div>
  );
}
