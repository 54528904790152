import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-datepicker/dist/react-datepicker.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/MagallanesRegular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/MagallanesMedium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/MagallanesBold.woff\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-magallanes\"}],\"variableName\":\"MagallanesFont\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"MontserratFont\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Drawers/NavigationDrawer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Drawers/UserDrawer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/MenuButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/UserButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/LayoutComponent.tsx")