'use client';

import { Bars3Icon } from '@heroicons/react/24/outline';

import { useNavigationDrawerActions } from '@/store/useNavigationDrawer';

export default function MenuButton() {
  const { openNavigationDrawer } = useNavigationDrawerActions();

  return (
    <button
      className="flex items-center justify-center px-3 transition md:hover:!opacity-100 group-hover:md:opacity-60"
      onClick={openNavigationDrawer}
    >
      <Bars3Icon className="h-6" />
    </button>
  );
}
