'use client';

import { useState } from 'react';

import Button, { ButtonVariant } from '@/components/Button';
import Input from '@/components/Input';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'next/navigation';
import { useAuthStore } from '@/store/useAuthStore';
import { useTranslations } from 'next-intl';
import { sendGTMEvent } from '@next/third-parties/google';
import PhoneInput from '@/components/PhoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function LoginForm() {
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState('');
  const router = useRouter();
  const setUser = useAuthStore((state) => state.setUser);
  const setAuthentication = useAuthStore((state) => state.setAuthentication);
  const translation = useTranslations('CustomerLogin');
  const validateLoginSchema = Yup.object().shape({
    phone_number: Yup.string()
      .test({
        name: 'phone_number',
        test: (value) => {
          if (!value) return false;
          return isValidPhoneNumber(value);
        },
        message: 'Ogiltigt telefonnummer',
      })
      .required('Telefonnummer krävs'),
    token: Yup.string(),
  });
  return (
    <div className="flex items-center justify-center">
      <Formik
        validationSchema={validateLoginSchema}
        initialValues={{ phone_number: '', token: '' }}
        validateOnBlur
        onSubmit={async (values, actions) => {
          setPhoneErrorMessage('');
          setTokenErrorMessage('');
          const response = await fetch(`/api/customer/login/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
          });
          const data = await response.json();
          if (data.success) {
            setShowAuthCode(true);
          }
          if (data.phone_error) {
            setPhoneErrorMessage(data.phone_error);
          }
          if (data.token_error) {
            setTokenErrorMessage(data.token_error);
          }
          if (data.redirect) {
            const response = await fetch(`/api/profile`, { method: 'GET' });
            if (response.ok) {
              const data = await response.json();
              data && setUser(data);
              setAuthentication(true);
              sendGTMEvent({
                event: 'login',
                customer: {
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email: data.email,
                  phone: data.phone_number,
                },
              });
              actions.resetForm();
              router.replace('/customer');
            }
          }
        }}
        enableReinitialize
      >
        {({ errors, touched, handleSubmit, isSubmitting, isValid }) => (
          <Form
            onSubmit={handleSubmit}
            className="mt-4 flex w-full flex-col gap-2"
          >
            <Field name="phone_number">
              {({ field }: FieldProps) => (
                <PhoneInput
                  type="tel"
                  autoComplete="tel"
                  placeholder="Telefonnummer"
                  {...field}
                />
              )}
            </Field>
            <p
              className={`${(phoneErrorMessage && 'pt-2') || (errors.phone_number && touched.phone_number && 'pt-2')}  text-sm text-red-500`}
            >
              {phoneErrorMessage && <span>{phoneErrorMessage}</span>}
              <ErrorMessage name="phone_number" />
            </p>

            {showAuthCode && (
              <>
                <Field name="token">
                  {({ field }: FieldProps) => (
                    <Input
                      type="text"
                      inputMode="numeric"
                      placeholder={translation('defaultAuth')}
                      className={`${errors.token || tokenErrorMessage ? 'border-red-500' : ''}`}
                      {...field}
                    />
                  )}
                </Field>

                <p
                  className={`${(tokenErrorMessage && 'pt-2') || (errors.token && touched.token && 'pt-2')}  text-sm text-red-500`}
                >
                  {tokenErrorMessage && <span>{tokenErrorMessage}</span>}
                  <ErrorMessage name="token" />
                </p>
              </>
            )}

            <Button
              disabled={isSubmitting || !isValid}
              variant={ButtonVariant.Primary}
              className=""
              type="submit"
            >
              {showAuthCode
                ? translation('button')
                : isSubmitting
                  ? 'Laddar...'
                  : translation('defaultButton')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
