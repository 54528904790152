'use client';

import Link from 'next/link';
import Header from '../Header';

import {
  useNavigationDrawerIsOpen,
  useNavigationDrawerActions,
} from '@/store/useNavigationDrawer';
import { GroupField } from '@prismicio/client';
import {
  Simplify,
  MainNavigationDocumentDataItemsItem,
} from '@/../prismicio-types';
import { PrismicNextLink } from '@prismicio/next';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export default function NavigationDrawer({
  items,
}: {
  items: GroupField<Simplify<MainNavigationDocumentDataItemsItem>>;
}) {
  const navigationDrawerIsOpen = useNavigationDrawerIsOpen();
  const { closeNavigationDrawer } = useNavigationDrawerActions();
  const pathName = usePathname();
  useEffect(() => {
    closeNavigationDrawer();
  }, [pathName, closeNavigationDrawer]);
  return (
    <div
      className={`
        pointer-events-none fixed inset-0 z-[10000] transform overflow-hidden bg-darkblue-950 transition-all duration-300 ease-in-out 
        ${navigationDrawerIsOpen ? 'bg-opacity-60 backdrop-blur-sm' : 'bg-opacity-0 backdrop-blur-none'}
      `}
    >
      <div
        className={`
        pointer-events-auto absolute right-0 h-full w-screen max-w-sm transform bg-white text-black transition-all duration-300 ease-in-out 
          ${navigationDrawerIsOpen ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        <Header onClick={closeNavigationDrawer} />
        <nav className="font-semibold text-neutral-700">
          <ul className="group px-4">
            {items.map((item) => (
              <li key={item.overrideTitle}>
                <PrismicNextLink
                  field={item.item}
                  className="text-md flex items-center rounded-full px-6 py-4 transition-colors duration-300 hover:bg-gray-50 hover:bg-opacity-50 hover:text-darkblue-950 md:hover:!opacity-100 group-hover:md:opacity-60"
                >
                  {item.overrideTitle}
                </PrismicNextLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {navigationDrawerIsOpen && (
        <div
          className="pointer-events-auto h-full w-screen cursor-pointer"
          onClick={closeNavigationDrawer}
        ></div>
      )}
    </div>
  );
}
