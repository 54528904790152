'use client';
import { useEffect } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { useAuthStore } from '@/store/useAuthStore';
import { useRouter } from 'next/navigation';
import { Profile } from '@/types/Profile';

export default function LayoutComponent({
  cookie,
  children,
}: {
  cookie: any;
  children: React.ReactNode;
}) {
  const setAuthentication = useAuthStore((state) => state.setAuthentication);
  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);
  const router = useRouter();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch('/api/profile');
        if (!response.ok) {
          setUser(null);
          setAuthentication(false);
          sendGTMEvent({ customer: null });
          return;
        }

        const data: Profile = await response.json();
        setUser(data);
        setAuthentication(true);
        sendGTMEvent({
          customer: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone_number,
          },
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (cookie) {
      getUser();
    }
    if (!cookie) {
      setUser(null);
      setAuthentication(false);
      sendGTMEvent({ customer: null });
      if (user) {
        router.push('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie, setAuthentication, setUser]);

  return <>{children}</>;
}
