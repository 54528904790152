import Link from 'next/link';
// import Button, { ButtonVariant } from './Button';
// import { Logout } from '@/actions/auth';
import { useRouter } from 'next/navigation';
import { useAuthStore } from '@/store/useAuthStore';
import { sendGTMEvent } from '@next/third-parties/google';

export default function UserRoutesNav({
  routes,
}: {
  routes: { name: string; link: string }[];
}) {
  const router = useRouter();
  const setUser = useAuthStore((state) => state.setUser);
  const setAuthentication = useAuthStore((state) => state.setAuthentication);
  return (
    <>
      <ul className="group font-semibold text-neutral-700">
        {routes.map((route, index) => (
          <li key={index}>
            <Link
              className="text-md hover:bg-light_gray hover:text-dark_blue flex items-center rounded-full px-6 py-4 transition-colors duration-300 hover:bg-opacity-50 md:hover:!opacity-100 group-hover:md:opacity-60"
              href={route.link}
            >
              {route.name}
            </Link>
          </li>
        ))}
      </ul>

      <button
        onClick={async () => {
          const response = await fetch(`/api/auth/logout`);
          if (response.redirected) {
            router.replace(response.url);
            setUser(null);
            setAuthentication(false);
            sendGTMEvent({ customer: null });
          }
        }}
        className="text-md hover:bg-light_gray hover:text-dark_blue flex w-full items-center rounded-full px-6 py-4 font-semibold text-neutral-700 transition-colors duration-300 hover:bg-opacity-50 md:hover:!opacity-100 group-hover:md:opacity-60"
      >
        Logga ut
      </button>
    </>
  );
}
