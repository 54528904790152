import { create } from 'zustand';

interface State {
  userDrawerIsOpen: boolean;
}

interface Actions {
  openUserDrawer: () => void;
  closeUserDrawer: () => void;
}

const INITIAL_STATE: State = {
  userDrawerIsOpen: false,
};

const useUserDrawerStore = create<State & Actions>((set) => ({
  userDrawerIsOpen: INITIAL_STATE.userDrawerIsOpen,
  openUserDrawer: () => set({ userDrawerIsOpen: true }),
  closeUserDrawer: () => set({ userDrawerIsOpen: false }),
}));

export const useUserDrawerIsOpen = () =>
  useUserDrawerStore((state: State) => state.userDrawerIsOpen);

export const useUserDrawerActions = () =>
  useUserDrawerStore((actions: Actions) => {
    return {
      openUserDrawer: actions.openUserDrawer,
      closeUserDrawer: actions.closeUserDrawer,
    };
  });
