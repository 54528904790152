import { XMarkIcon as XMarkOutline } from '@heroicons/react/24/outline';

export default function Header({ onClick }: { onClick: () => void }) {
  return (
    <div className="flex h-16 justify-end p-4">
      <button
        className="flex aspect-square h-full items-center justify-center rounded-full bg-white text-darkblue-950"
        onClick={onClick}
      >
        <XMarkOutline className="h-6" />
      </button>
    </div>
  );
}
