import { create } from 'zustand';

interface State {
  navigationDrawerIsOpen: boolean;
}

interface Actions {
  openNavigationDrawer: () => void;
  closeNavigationDrawer: () => void;
}

const INITIAL_STATE: State = {
  navigationDrawerIsOpen: false,
};

const useNavigationDrawerStore = create<State & Actions>((set) => ({
  navigationDrawerIsOpen: INITIAL_STATE.navigationDrawerIsOpen,
  openNavigationDrawer: () => set({ navigationDrawerIsOpen: true }),
  closeNavigationDrawer: () => set({ navigationDrawerIsOpen: false }),
}));

export const useNavigationDrawerIsOpen = () =>
  useNavigationDrawerStore((state: State) => state.navigationDrawerIsOpen);

export const useNavigationDrawerActions = () =>
  useNavigationDrawerStore((actions: Actions) => {
    return {
      openNavigationDrawer: actions.openNavigationDrawer,
      closeNavigationDrawer: actions.closeNavigationDrawer,
    };
  });
