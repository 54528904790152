import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export enum UserRole {
  Customer = 'CUSTOMER',
  Hairdresser = 'HAIRDRESSER',
}

interface State {
  userRole: UserRole;
}

interface Actions {
  setUserRole: (role: UserRole) => void;
}

const INITIAL_STATE: State = {
  userRole: UserRole.Customer,
};

const useUserDrawerStore = create(
  persist<State & Actions>(
    (set) => ({
      userRole: INITIAL_STATE.userRole,
      setUserRole: (role: UserRole) => set({ userRole: role }),
    }),
    {
      name: 'userRole',
    }
  )
);

export const useUserRole = () =>
  useUserDrawerStore((state: State) => state.userRole);

export const useUserRoleActions = () =>
  useUserDrawerStore((actions: Actions) => {
    return {
      setUserRole: actions.setUserRole,
    };
  });
